/* eslint-disable jsx-a11y/img-redundant-alt */
import { Logo } from "../types/site.interface";
import { Headers } from "../types/header.interface";

const Header = ({ logo }: { logo: Logo | string }) => {
  return (
    <div className="jpdesign_header">
      <div className="container">
        <div className="inner">
          <div className="logo">
            <a href="#">
              {logo && (
                <img
                  src={logo ? logo.toString() : "img/logo/logo.png"}
                  alt="image"
                />
              )}
            </a>
          </div>
          <div className="menu">
            <ul className="anchor_nav">
              <li className="current">
                <a href="#home">{Headers.HOME}</a>
              </li>
              <li>
                <a href="#about">{Headers.ABOUT}</a>
              </li>
              <li>
                <a href="#portfolio">{Headers.PORTFOLIO}</a>
              </li>
              <li>
                <a href="#background">{Headers.BACKGROUND}</a>
              </li>
              <li>
                <a href="#contact">{Headers.CONTACT}</a>
              </li>
              <li className="download_cv">
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/personalportfolio-4caf3.appspot.com/o/JeanPerez_Resume_2024_3Pages.pdf?alt=media&token=d2694441-5d5c-45ba-8dc2-f41725ac86fa"
                  target="_blank"
                  download=""
                >
                  <span>Download CV</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
