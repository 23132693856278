/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from "react";
import { fatchData } from "../utilits";
import { useQuery } from "react-query";
import { Info } from "../types/info.interface";

const fetchInfo = async () => {
  const res = await fetch("/static/info.json");
  const data = await res.json();
  return data;
};

const Contact = () => {
  const { data, status } = useQuery("info", fetchInfo) as {
    data: Info;
    status: string;
  };

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "error") {
    return <div>Error</div>;
  }

  return (
    <div className="jpdesign_section" id="contact">
      <div className="jpdesign_contact">
        <div className="container">
          <div className="jpdesign_main_title" data-align="center">
            <span>Contact Me</span>
            <h3>Let's Connect</h3>
            <p>Contact me via LinkedIn for the quickest response.</p>
            <div className="brush_2 wow zoomIn" data-wow-duration="2s">
              <a
                href="https://www.linkedin.com/in/jeancarlosp/"
                target="_blank"
              >
                <img src="img/svg/linkedin.svg" alt="image" />
              </a>
            </div>
          </div>
          <div className="jpdesign_main_title" data-align="center">
            <span>Located in Florida</span>
            <h4>Open to Remote and Local Opportunities</h4>
          </div>
          {/* <div className="jpdesign_map wow fadeInUp" data-wow-duration="1s">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  height={375}
                  style={{ width: "100%" }}
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed/v1/view?zoom=11&center=25.7360%2C-80.3157&key=AIzaSyB3hCGX0Gq63UfHBcpdnYCVXaF94wEwMew"
                />
                <br />
              </div>
            </div>
          </div> */}
          <div className="brush_1 wow fadeInLeft" data-wow-duration="1s">
            <img src="img/logo/banner.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
