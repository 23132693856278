import React, { useState, useImperativeHandle, forwardRef } from "react";
import Notification from "./Notifications";
import { Skill } from "../types/info.interface";

interface NotificationItem {
  id: number;
  skill: Skill;
  position: { x: number; y: number };
  timeoutId: NodeJS.Timeout;
}

interface NotificationManagerProps {}

const NotificationManager = forwardRef(
  (props: NotificationManagerProps, ref) => {
    const [notification, setNotification] = useState<NotificationItem | null>(
      null
    );

    // Method to add notification
    const addNotification = (
      skill: Skill,
      position: { x: number; y: number }
    ) => {
      // Clear any existing notification
      if (notification) {
        clearTimeout(notification.timeoutId);
        setNotification(null);
      }

      const id = Date.now();
      const timeoutId = setTimeout(() => {
        setNotification(null);
      }, 10000);

      const newNotification: NotificationItem = {
        id,
        skill,
        position,
        timeoutId,
      };

      setNotification(newNotification);
    };

    // Expose the addNotification function to the parent component via ref
    useImperativeHandle(ref, () => ({
      addNotification,
    }));

    return (
      <div>
        {notification && (
          <Notification
            key={notification.id}
            skill={notification.skill}
            position={notification.position}
          />
        )}
      </div>
    );
  }
);

export default NotificationManager;