import React, { useEffect, useRef, useState } from "react";

interface CustomVisibilitySensorProps {
  children: (isVisible: boolean) => React.ReactNode;
  onChange?: (isVisible: boolean) => void;
}

const CustomVisibilitySensor: React.FC<CustomVisibilitySensorProps> = ({
  children,
  onChange,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (onChange) {
          onChange(entry.isIntersecting);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [onChange]);

  return <div ref={observerRef}>{children(isVisible)}</div>;
};

export default CustomVisibilitySensor;
