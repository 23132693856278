import React from "react";
import {
  IEducationExperience,
  IWorkExperience,
} from "../types/firebase.store.interface";
import { format } from "date-fns";

interface ModalContentProps {
  activeTab: "role" | "responsibilities" | "about";
  data: IWorkExperience | IEducationExperience;
}

function isWorkExperience(experience: any): experience is IWorkExperience {
  return (experience as IWorkExperience).company !== undefined;
}

const ModalContent: React.FC<ModalContentProps> = ({
  activeTab,
  data: experience,
}) => {
  const currentTime = format(new Date(), "hh:mm:ss a");
  const isWork = isWorkExperience(experience);

  return (
    <div className="console-log">
      <div className="log-header">
        <span className="branch">main</span> | {activeTab}
      </div>
      <br />
      <div className="log-contents">
        {activeTab === "role" && (
          <p>
            {isWork ? experience.summary : experience.description}
            <span className="cursor">|</span>
          </p>
        )}
        {activeTab === "responsibilities" && (
          <ul>
            {experience.details &&
              experience.details.map((detail, index) => (
                <li key={index}>
                  • {detail}
                  <br />
                </li>
              ))}
            <span className="cursor">|</span>
          </ul>
        )}
        {activeTab === "about" && (
          <p>
            {isWork ? experience.companyAbout : experience.about}
            <span className="cursor">|</span>
          </p>
        )}
      </div>
      <div className="log-status">
        <span className="version">Node 20.18.0</span>
        <span className="time">{currentTime}</span>
      </div>
    </div>
  );
};

export default ModalContent;
