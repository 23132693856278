import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { IProjects } from "../types/firebase.store.interface";
import ParticleEffects from "src/layout/ParticleEffects";
import LinkedSkillInfo from "./SkillsChain";
import ModalContent from "./ModalContent";
import ProjectsModalContent from "./ProjectsModalContent";

interface ProjectsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  project: IProjects | null;
}
Modal.setAppElement("#root"); // Set the app element for accessibility

const ProjectsModal: React.FC<ProjectsModalProps> = ({
  isOpen,
  onRequestClose,
  project,
}) => {
  const [activeTab, setActiveTab] = useState<"techStack" | "about" | "gallery">(
    "about"
  );

  // Disable background scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable background scroll
    } else {
      document.body.style.overflow = ""; // Re-enable background scroll
    }

    // Cleanup to ensure scroll is enabled when modal is closed
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!project) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Project Details"
      className="modal project-modal"
      overlayClassName="overlay"
    >
      <div className="modal-container">
        <div className="modal-header">
          <img src={project.mainImage} alt={project.name} />
          <div className="modal-subtitle">
            <h1>
              <strong>{project.name}</strong>
            </h1>
            <h2>{project.type}</h2>
            <h3>
              {project.startDate} -{" "}
              {project.endDate ? project.endDate : "Present"}
            </h3>
            <span className={`modal-tag ${project.projectType}`}>
              {project.projectType}
            </span>
          </div>
        </div>
        <div className="modal-tabs">
          <button
            onClick={() => setActiveTab("about")}
            className={activeTab === "about" ? "active" : ""}
          >
            About the Project
          </button>
          <button
            onClick={() => setActiveTab("techStack")}
            className={activeTab === "techStack" ? "active" : ""}
          >
            Tech Stack
          </button>
          <button
            onClick={() => setActiveTab("gallery")}
            className={activeTab === "gallery" ? "active" : ""}
          >
            Gallery
          </button>
        </div>
        <ProjectsModalContent activeTab={activeTab} project={project} />
        <div className="skills-used-wrapper">
          <LinkedSkillInfo usedSkills={project.techStack} appearance="modal" />
        </div>
        <button onClick={onRequestClose} className="close-modal">
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ProjectsModal;
