/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import ReactDOM from "react-dom";
// import WOW from "wowjs/dist/wow";
// const WOW = require("wowjs");
const preloader_ = () => {
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  )
    ? true
    : false;
  let preloader = document.getElementById("preloader");

  if (preloader) {
    if (!isMobile) {
      setTimeout(function () {
        preloader.classList.add("preloaded");
      }, 800);
      setTimeout(function () {
        preloader.remove();
      }, 2000);
    } else {
      preloader.remove();
    }
  }
};

export const wowJsAnimation = () => {
  setTimeout(async () => {
    if (typeof window !== "undefined") {
      const { default: WOW } = await import("wowjs/dist/wow");
      window.WOW = WOW;
      new WOW().init();
    }
  }, 500);
};

export const customCursor = () => {
  const myCursor = document.querySelectorAll(".mouse-cursor");
  const hamburger = document.querySelector(".hamburger");
  const kura_tm_topbar = document.querySelector(".kura_tm_topbar");
  const pointer = document.querySelector(".cursor-pointer");
  const e = document.querySelector(".cursor-inner");
  const t = document.querySelector(".cursor-outer");

  function mouseEvent(element) {
    element.addEventListener("mouseenter", () => {
      e.classList.add("cursor-hover");
      t.classList.add("cursor-hover");
    });
    element.addEventListener("mouseleave", () => {
      e.classList.remove("cursor-hover");
      t.classList.remove("cursor-hover");
    });
  }

  if (myCursor.length) {
    if (document.body) {
      let n,
        i = 0,
        o = false;

      window.onmousemove = (s) => {
        if (!o) {
          t.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
          e.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
          n = s.clientY;
          i = s.clientX;
        }
      };

      document.body.addEventListener("mouseenter", () => {
        const a = document.querySelectorAll("a");
        e.classList.add("cursor-inner");
        t.classList.add("cursor-outer");

        a.forEach((element) => mouseEvent(element));
        if (hamburger) mouseEvent(hamburger);
        if (kura_tm_topbar) mouseEvent(kura_tm_topbar);
        if (pointer) mouseEvent(pointer);
      });

      e.style.visibility = "visible";
      t.style.visibility = "visible";
    }
  }
};

export const preloader = () => {
  preloader_();
  setTimeout(() => {
    document.querySelector("body").classList.add("opened");
  }, 3000);
};

export const aTagClick = () => {
  const aTags = document.querySelectorAll("[href='#']");
  aTags.forEach((a) => {
    a.addEventListener("click", (e) => {
      e.preventDefault();
    });
  });
};

const allParents = document.querySelectorAll(".parent");
allParents.forEach((parent) => {
  // create a new observer for each parent container
  const observer = new MutationObserver(function (mutationList, observer) {
    mutationList.forEach((mutation) => {
      // get the text element, see the html markup
      // at the top for reference
      const parent = mutation.target;
      const textContainer = parent.firstElementChild;
      const text = textContainer.firstElementChild;

      // resize the text
      resizeText({ element: text, step: 0.5 });
    });
  });

  // let's observe only our required attributes
  observer.observe(parent, {
    attributeFilter: ["style"],
  });
});

const isOverflown = ({ clientHeight, scrollHeight }) =>
  scrollHeight > clientHeight;

export const resizeText = ({
  element,
  elements,
  minSize = 12,
  maxSize = 512,
  step = 1,
  unit = "px",
}) => {
  (elements || [element]).forEach((el) => {
    let i = minSize;
    let overflow = false;

    const parent = el.parentNode;

    while (!overflow && i < maxSize) {
      el.style.fontSize = `${i}${unit}`;
      overflow = isOverflown(parent);

      if (!overflow) i += step;
    }

    // revert to last state where no overflow happened
    el.style.fontSize = `${i - step}${unit}`;
  });
};

const throttle = (func, timeFrame) => {
  let lastTime = 0;
  return (...args) => {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
};

export const throttledResize = throttle(resizeText, 25);


export const activeSkillProgress = () => {
  const progress_inner = document.querySelectorAll(".skillsInner___"),
    triggerBottom = (window.innerHeight / 5) * 5;
  progress_inner.forEach((box) => {
    const boxTop = box.getBoundingClientRect().top,
      boxElement = box.getElementsByClassName("bar"),
      label = box.getElementsByClassName("label"),
      number = box.getElementsByClassName("number"),
      boxItem = boxElement[0],
      pWidth = box.getAttribute("data-value"),
      width = calculateSkillWidth(pWidth),
      pColor = box.getAttribute("data-color");
    if (boxTop < triggerBottom) {
      boxItem.classList.add("open");
      label[0].classList.add("opened");
      const stringWidth = Number(width) - 18;
      number[0].style.left = `${stringWidth}%`;
      number[0].style.color = pColor;
      boxItem.getElementsByClassName("bar_in")[0].style.width = `${width}%`;
      boxItem.getElementsByClassName("bar_in")[0].style.backgroundColor =
        pColor;
    } else {
      boxItem.classList.remove("open");
      label[0].classList.remove("opened");
      number[0].style.left = `${120}%`;
    }
  });
};

const calculateSkillWidth = (width) => {
  let amount = Number(width) + 10;
  if (amount > 100) {
    amount = 100;
  }
  return `${amount}`;
};
// Data image
export const dataImage = () => {
  let d = document.querySelectorAll("[data-img-url");
  for (let i = 0; i < d.length; i++) {
    const element = d[i];
    element.style.backgroundImage = `url(${element.getAttribute(
      "data-img-url"
    )})`;
  }
};

export const scroll_ = () => {
  const sections = document.querySelectorAll(".jpdesign_section");
  const navLi = document.querySelectorAll(".anchor_nav li");
  let current = "";
  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.clientHeight;
    if (pageYOffset >= sectionTop - sectionHeight / 3) {
      current = section.getAttribute("id");
    }
  });

  navLi.forEach((li) => {
    li.classList.remove("current");
    if (li.getElementsByTagName("a")[0].getAttribute("href") == `#${current}`) {
      li.classList.add("current");
    }
  });
};
export const stickyNav = () => {
  let offset = window.scrollY;
  const stickys = document.querySelectorAll(".jpdesign_header");
  stickys.forEach((sticky) => {
    if (sticky) {
      if (offset > 100) {
        sticky.classList.add("animate");
      } else {
        sticky.classList.remove("animate");
      }
    }
  });
};

export const scrollTop = () => {
  var bar = document.querySelector(".progressbar");
  var line = document.querySelector(".progressbar .line");

  // Check if elements are found in the DOM
  if (!bar || !line) {
    console.error("Element(s) not found:", { bar, line });
    return; // Exit the function if elements are not found
  }

  var documentHeight = document.documentElement.scrollHeight;
  var windowHeight = window.innerHeight;
  var winScroll = window.scrollY;
  var value = (winScroll / (documentHeight - windowHeight)) * 100;
  var position = value;

  if (winScroll > 100) {
    bar.classList.add("animate");
    line.style.height = position + "%";
  } else {
    bar.classList.remove("animate");
  }
};

export const fatchData = async (url) => {
  const res = await fetch(`${url}`);
  const data = await res.json();
  return data;
};

export const portfolioHover = () => {
  const jpdesign_portfolio_animation_wrap = document.querySelectorAll(
      ".jpdesign_portfolio_animation_wrap"
    ),
    jpdesign_portfolio_titles = document.querySelector(
      ".jpdesign_portfolio_titles"
    );
  jpdesign_portfolio_animation_wrap.forEach((element) => {
    element.addEventListener("mousemove", () => {
      let title = element.getAttribute("data-title"),
        category = element.getAttribute("data-category");
      if (title) {
        jpdesign_portfolio_titles.classList.add("visible");
        jpdesign_portfolio_titles.innerHTML =
          title + '<span class="work__cat">' + category + "</span>";
      }
      document.addEventListener("mousemove", (e) => {
        jpdesign_portfolio_titles.style.left = `${e.clientX - 10}px`;
        jpdesign_portfolio_titles.style.top = `${e.clientY + 25}px`;
      });
    });
    element.addEventListener("mouseleave", () => {
      jpdesign_portfolio_titles.classList.remove("visible");
    });
  });
};
