import React from "react";
import CountUp from "react-countup";
import CustomVisibilitySensor from "./CustomVisibilitySensor";

interface CounterProps {
  end: number;
  children?: React.ReactNode;
}

const Counter = ({ end, children }: CounterProps) => {
  return (
    <CustomVisibilitySensor>
      {(isVisible) => (
        <div>
          {isVisible ? <CountUp end={end} /> : null}+ {children}{" "}
        </div>
      )}
    </CustomVisibilitySensor>
  );
};

export default Counter;
