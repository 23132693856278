import React from "react";
import { IProjects } from "../types/firebase.store.interface";
import { format } from "date-fns";

interface ProjectsModalContentProps {
  activeTab: "techStack" | "about" | "gallery";
  project: IProjects;
}

const ProjectsModalContent: React.FC<ProjectsModalContentProps> = ({
  activeTab,
  project: projects,
}) => {
  const currentTime = format(new Date(), "hh:mm:ss a");
  return (
    <div className="console-log">
      <div className="log-header">
        <span className="branch">main</span> | {activeTab}
      </div>
      <br />
      <div className="log-contents">
        {activeTab === "about" && (
          <p>
            {projects.description}
            <span className="cursor">|</span>
          </p>
        )}
        {activeTab === "techStack" && (
          <ul>
            {projects.details &&
              projects.details.map((detail, index) => (
                <li key={index}>
                  • {detail}
                  <br />
                </li>
              ))}
            <span className="cursor">|</span>
          </ul>
        )}
        {activeTab === "gallery" && (
          <p>
            {projects.about}
            <span className="cursor">|</span>
          </p>
        )}
      </div>
      <div className="log-status">
        <span className="version">Node 20.18.0</span>
        <span className="time">{currentTime}</span>
      </div>
    </div>
  );
};

export default ProjectsModalContent;
