/* eslint-disable jsx-a11y/anchor-is-valid */
import Isotope from "isotope-layout";
import parse from "html-react-parser";
import { Key, useEffect, useRef, useState } from "react";
import { dataImage, portfolioHover } from "../utilits";
import { useProjectsStore, useSkillsStore } from "src/store/firebase.store";
import { IProjects } from "src/types/firebase.store.interface";
import PortfolioGallery from "src/components/PortfolioGallery";

const Portfolio = () => {
  useEffect(() => {
    dataImage();
    portfolioHover();
  }, []);

  // Isotope
  const isotope: any = useRef();
  const [filterKey, setFilterKey] = useState("*");
  useEffect(() => {
    setTimeout(() => {
      if (isotope) {
        isotope.current = new Isotope(".gallery_zoom", {
          itemSelector: ".grid-item",
          layoutMode: "masonry",
          percentPosition: true,
          masonry: {
            columnWidth: ".grid-item",
          },
          // animationOptions: {
          //   duration: 750,
          //   easing: "linear",
          //   queue: false,
          // },
        });
      }
    }, 500);
    return () => {
      if (isotope) isotope.current?.destroy();
    };
  }, [isotope]);
  useEffect(() => {
    if (isotope.current) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey]);
  const handleFilterKeyChange = (key: any) => () => {
    setFilterKey(key);
  };
  const activeBtn = (value: any) => (value === filterKey ? "current" : "");

  // Popup
  const [popup, setPopup] = useState(false);

  // Use the useProjectsStore to fetch projects
  const { data: projects, loading, error, fetchProjects } = useProjectsStore();
  const { data: skills, fetchSkills } = useSkillsStore();
  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    fetchSkills();
  }, [fetchSkills]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "error") {
    return <div>Error</div>;
  }

  return (
    <div className="jpdesign_section" id="portfolio">
      <div className="portfolio_title_section_container">
        <div className="portfolio_title_section">
          <div className="brush_1 wow zoomIn" data-wow-duration="2s">
            <img src="img/svg/start-up.svg" alt="image" />
          </div>
          <div className="jpdesign_main_title" data-align="center">
            <span>Portfolio & Projects</span>
            <h3>My Personal Software Projects</h3>

            <p>
              Most of my projects are built with React, Node, Express, and
              TypeScript. I also have experience with Python, Rust, C++, and
              Java. These are just a collection of my favorite projects.
            </p>
          </div>
          <div className="brush_2 wow fadeInRight" data-wow-duration="5s">
            <img src="img/svg/online-education.svg" alt="image" />
          </div>
        </div>
        <div className="portfolio_inline_icons" data-align="center">
          {skills
            ?.sort(() => Math.random() - 0.5)
            .slice(0, 5)
            .map(
              (skill: { icon: string; name: any }, i: Key | null | undefined) =>
                skill.icon && (
                  <ul key={i}>
                    <span className={`portfolio_inline_icons animPulse`}>
                      {parse(skill.icon)}
                    </span>
                  </ul>
                )
            )}
        </div>
      </div>
      <div className="jpdesign_portfolio">
        <PortfolioGallery />
      </div>
    </div>
  );
};
export default Portfolio;

{
  /* <li className="youtube grid-item">
<div className="inner">
  <div
    className="entry jpdesign_portfolio_animation_wrap"
    data-title="Mockup Shape"
    data-category="Youtube"
  >
    <a
      className="popup-youtube"
      href="https://www.youtube.com/embed/7e90gBu4pas?autoplay=1"
    >
      <img src="img/thumbs/42-56.jpg" alt="image" />
      <div
        className="main"
        data-img-url="img/portfolio/1.jpg"
      />
    </a>
  </div>
  <div className="mobile_title">
    <h3>Mockup Shape</h3>
    <span>Youtube</span>
  </div>
</div>
</li>
<li className="vimeo grid-item">
<div className="inner">
  <div
    className="entry jpdesign_portfolio_animation_wrap"
    data-title="Ave Bottle"
    data-category="Vimeo"
  >
    <a
      className="popup-vimeo"
      href="https://player.vimeo.com/video/337293658?autoplay=1"
    >
      <img src="img/thumbs/42-34.jpg" alt="image" />
      <div
        className="main"
        data-img-url="img/portfolio/2.jpg"
      />
    </a>
  </div>
  <div className="mobile_title">
    <h3>Ave Bottle</h3>
    <span>Vimeo</span>
  </div>
</div>
</li>
<li className="soundcloud grid-item">
<div className="inner">
  <div
    className="entry jpdesign_portfolio_animation_wrap"
    data-title="Magic Art"
    data-category="Soundcloud"
  >
    <a
      className="soundcloude_link mfp-iframe audio"
      href="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/471954807&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
    >
      <img src="img/thumbs/42-56.jpg" alt="image" />
      <div
        className="main"
        data-img-url="img/portfolio/3.jpg"
      />
    </a>
  </div>
  <div className="mobile_title">
    <h3>Magic Art</h3>
    <span>Soundcloud</span>
  </div>
</div>
</li>
<li className="popup grid-item">
<div className="inner">
  <div
    className="entry jpdesign_portfolio_animation_wrap"
    data-title="Scott Felix"
    data-category="Popup"
  >
    <a className="zoom" href="img/portfolio/5.jpg">
      <img src="img/thumbs/42-56.jpg" alt="image" />
      <div
        className="main"
        data-img-url="img/portfolio/5.jpg"
      />
    </a>
  </div>
  <div className="mobile_title">
    <h3>Blue Lemon</h3>
    <span>Popup</span>
  </div>
</div>
</li>
<li className="popup grid-item">
<div className="inner">
  <div
    className="entry jpdesign_portfolio_animation_wrap"
    data-title="Art Stone"
    data-category="Popup"
  >
    <a className="zoom" href="img/portfolio/4.jpg">
      <img src="img/thumbs/42-34.jpg" alt="image" />
      <div
        className="main"
        data-img-url="img/portfolio/4.jpg"
      />
    </a>
  </div>
  <div className="mobile_title">
    <h3>Art Stone</h3>
    <span>Popup</span>
  </div>
</div>
</li>
<li className="detail grid-item" onClick={() => setPopup(true)}>
<div className="inner">
  <div
    className="entry jpdesign_portfolio_animation_wrap"
    data-title="Global Evolution"
    data-category="Detail"
  >
    <a className="portfolio_popup" href="#">
      <img src="img/thumbs/42-34.jpg" alt="image" />
      <div
        className="main"
        data-img-url="img/portfolio/6.jpg"
      />
    </a>
  </div>
  <div className="mobile_title">
    <h3>Global Evolution</h3>
    <span>Detail</span>
  </div>
</div>
</li> */
}
