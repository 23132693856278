import React, { useEffect, useRef, useState } from "react";
import { useSkillsStore } from "src/store/firebase.store";
import parse from "html-react-parser";
import { SkillNames } from "src/types/info.interface";
import ReactDOM from "react-dom";

interface LinkedSkillInfoProps {
  usedSkills: string[];
  appearance: "modal" | "timeline";
  style?: React.CSSProperties;
}

const LinkedSkillInfo: React.FC<LinkedSkillInfoProps> = ({
  usedSkills,
  appearance,
  style,
}) => {
  const { data: allSkills, error, loading, fetchSkills } = useSkillsStore();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [duplicatedSkills, setDuplicatedSkills] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
    visibility: "visible" | "hidden";
  } | null>(null);


  useEffect(() => {
    if (appearance === "modal") {
      setIsModalOpen(true);
    }
  }, [appearance]);

  useEffect(() => {
    if (Array.isArray(allSkills) && allSkills.length > 0 && isModalOpen) {
      const filteredSkills = allSkills.filter((skill) =>
        usedSkills.includes(skill.name.replace(/\s+/g, ""))
      );

      if (filteredSkills.length > 0 && carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        const iconWidth = 80; // Assuming each icon has a fixed width of 80px including margin
        const numIconsNeeded = Math.ceil(carouselWidth / iconWidth);
        const iconsToDuplicate = Math.ceil(
          numIconsNeeded / filteredSkills.length
        );
        const newDuplicatedSkills = Array(iconsToDuplicate)
          .fill(filteredSkills)
          .flat();
        setDuplicatedSkills(newDuplicatedSkills);
      }
    }
  }, [allSkills, usedSkills, isModalOpen]);

  const handleMouseOver = (event: React.MouseEvent, skillName: string) => {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    setTooltipContent(
      SkillNames[skillName.replace(/\s+/g, "") as keyof typeof SkillNames]
    );
    setTooltipPosition({
      top: rect.top,
      left: rect.left + rect.width / 2,
      visibility: "visible",
    });
  };

  const handleMouseOut = () => {
    setTooltipContent(null);
    setTooltipPosition(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading skills: {error}</div>;
  }

  return (
    <>
      {appearance === "modal" ? (
        <div className="carousel-container" ref={carouselRef}>
          <div className="carousel">
            {Array.isArray(duplicatedSkills) &&
              duplicatedSkills.map((skill, index) => (
                <div
                  key={index}
                  className="skill-info-carousel-wrapper"
                  style={{ position: "relative" }}
                  onMouseOver={(e) => handleMouseOver(e, skill.name)}
                  onMouseOut={handleMouseOut}
                >
                  <span>{parse(skill.icon)}</span>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="linked-skill-info" style={style}>
          {allSkills &&
            allSkills
              .filter((skill) =>
                usedSkills.includes(skill.name.replace(/\s+/g, ""))
              )
              .map((skill, index) => (
                <div key={index} className="skill-info-wrapper">
                  <span>{parse(skill.icon)}</span>
                  <div className="tooltip">
                    {
                      SkillNames[
                        skill.name.replace(
                          /\s+/g,
                          ""
                        ) as keyof typeof SkillNames
                      ]
                    }
                  </div>
                </div>
              ))}
        </div>
      )}
      {tooltipContent &&
        tooltipPosition &&
        carouselRef.current &&
        ReactDOM.createPortal(
          <div
            className={`tooltip-carousel ${
              tooltipContent ? "fade-in" : "fade-out"
            }`}
            style={{
              transform: "translateX(-50%)",
              zIndex: 1000,

              color: "#fff",
              textAlign: "center",
              borderRadius: "5px",
              padding: "5px",
              opacity: tooltipContent ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
              visibility: tooltipPosition.visibility,
            }}
          >
            {tooltipContent}
          </div>,
          carouselRef.current
        )}
    </>
  );
};

export default LinkedSkillInfo;
