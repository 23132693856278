import React from "react";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { Skill, SkillNames } from "../types/info.interface";
import parse from "html-react-parser";

const getYearsBasedOnValue = (value: number) => {
  return Math.floor(value / 10);
};

const NotificationWrapper = styled(animated.div)<{ x: number; y: number }>`
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 10px; /* Add margin to separate multiple notifications */
  width: 15%;
  position: absolute;
  background: rgba(255, 255, 255, 0.05); /* Transparent background */
  backdrop-filter: blur(15px); /* Blur the background */
  border: 5px solid transparent; /* Transparent border as a base */
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;

  & svg {
    width: 25px;
    height: 25px; /* Optional: Set height if needed */
  }
`;

interface NotificationProps {
  skill: Skill;
  position: { x: number; y: number };
}

const Notification: React.FC<NotificationProps> = ({ skill, position }) => {
  const styles = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 220, friction: 20 },
  });

  return (
    <NotificationWrapper style={styles} x={position.x} y={position.y}>
      <div>
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ width: "25px", height: "25px", marginRight: "8px" }}>
            {parse(skill.icon)}
          </span>
          <strong>
            {
              SkillNames[
                skill.name.replace(/\s+/g, "") as keyof typeof SkillNames
              ]
            }
          </strong>
        </h4>
        Over {getYearsBasedOnValue(skill.value)} Years Experience
        <p>{skill.description}</p>
      </div>
    </NotificationWrapper>
  );
};

export default Notification;