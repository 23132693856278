import React, { useState } from "react";
import parse from "html-react-parser";
import styled, { css, keyframes } from "styled-components";
import { Skill } from "../types/info.interface";

interface SkillBadgeProps {
  skills: Skill[];
  onSkillClick?: (skill: Skill) => void;
}

const expandAnimation = keyframes`
  from {
    width: 50px;
    height: 50px;
  }
  to {
    width: 300px;
    height: auto;
  }
`;

const shrinkAnimation = keyframes`
  from {
    width: 300px;
    height: auto;
  }
  to {
    width: 50px;
    height: 50px;
  }
`;

const SkillInfo = styled.div<{ expanded: boolean }>`
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  margin: 10px;
  position: relative;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: ${(props) => (props.expanded ? "300px" : "50px")};
  height: ${(props) => (props.expanded ? "auto" : "50px")};
  overflow: ${(props) => (props.expanded ? "auto" : "hidden")};
  animation: ${(props) => (props.expanded ? expandAnimation : shrinkAnimation)}
    0.3s forwards;

  .skillsInfoIcon {
    position: absolute;
    width: 50px;
    top: 0px;
    right: 0px;
    height: 50px;
  }

  .skillsInfoTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .skillsInfoDescription {
    font-size: 0.6rem;
    white-space: normal;
    line-height: 1.5; /* Adjust line height to control spacing between lines */
    margin: 0; /* Remove any extra margin */
    padding: 0; /* Remove any extra padding */
  }
`;

const SkillBadges: React.FC<SkillBadgeProps> = ({ skills }) => {
  const [expandedSkill, setExpandedSkill] = useState<Skill | null>(null);

  function onSkillClick(skill: Skill): void {
    setExpandedSkill(expandedSkill === skill ? null : skill);
  }

  return (
    <>
      {skills.slice(0, 6).map((skill, i) =>
        skill.icon ? (
          <div key={i} onClick={() => onSkillClick(skill)}>
            {expandedSkill === skill ? (
              <SkillInfo
                expanded={expandedSkill === skill}
                className={`skills ${skill.name} anim_moveBottom`}
                id={expandedSkill === skill ? "expanded" : undefined}
              >
                <span className={`skillsInfoIcon`}>{parse(skill.icon)}</span>
                <p className="skillsInfoTitle">
                  {skill.name.replace(
                    /\w\S*/g,
                    (txt) =>
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  )}
                </p>
                <p
                  className="skillsInfoDescription"
                  style={{
                    whiteSpace: "normal",
                  }}
                >
                  {skill.description}
                </p>
              </SkillInfo>
            ) : (
              <span className={`skills ${skill.name} anim_moveBottom`}>
                {parse(skill.icon)}
              </span>
            )}
          </div>
        ) : null
      )}
    </>
  );
};

export default SkillBadges;
