import React from "react";
import { IProjects } from "src/types/firebase.store.interface";
import LinkedSkillInfo from "./SkillsChain";

interface PortfolioFrameProps {
  project: IProjects;
  onClick: () => void;
}

const PortfolioFrame: React.FC<PortfolioFrameProps> = ({
  project,
  onClick,
}) => {
  return (
    <li className={`grid-item`} onClick={onClick}>
      <div className="ProjectFrameContainer">
        <div className={`ProjectFrameTag  ${project.projectType}`}>
          <span>{project.projectType}</span>
        </div>
        <div className="ProjectFrameImg">
          <img src={project.mainImage} alt="image" />
        </div>
        <div className="ProjectFrameContext">
          <h3>{project.name}</h3>
          <div className="ProjectFrameSkills">
            <LinkedSkillInfo
              usedSkills={project.techStack}
              appearance="timeline"
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default PortfolioFrame;
