import React from 'react';
import { SentryRoutes } from "./utilities/Sentry/instrumentation";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Highlights from "./components/Highlights";
import { QueryClient, QueryClientProvider } from "react-query";
import "./styles/globals.css";
import Layout from "./layout/Layout";
import ParticleEffects from "./layout/ParticleEffects";
import About from "./pages/AboutMe";
import Portfolio from "./pages/Portfolio";
import SkillProgress from "./components/SkillsProgress";
import Background from "./pages/Background";
import Contact from "./pages/Contact";
import { DockHeader } from "./layout/DockHeader/DockHeader";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ParticleEffects />
      <BrowserRouter>
        <Layout dark={true}>
          <SentryRoutes>
            <Route
              path="/"
              element={
                <>
                  <Home />
                  <Highlights />
                  <About />
                  <Portfolio />
                  <SkillProgress />
                  <Background />
                  <Contact />
                </>
              }
            />
            <Route
              path="/home"
              element={
                <>
                  <Home />
                  <Highlights />
                  <About />
                  <Portfolio />
                  <SkillProgress />
                  <Background />
                  <Contact />
                </>
              }
            />
            <Route path="/highlights" element={<Highlights />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/background" element={<Background />} />
          </SentryRoutes>
        </Layout>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
