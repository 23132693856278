import React, { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { Skill, SkillNames } from "src/types/info.interface";
import Counter from "./Counter";

interface SkillProgressBarProps {
  skill: Skill;
  onClick: (skill: Skill, event: React.MouseEvent) => void;
}

const getYearsBasedOnValue = (value: number) => {
  return Math.floor(value / 10);
};

const calculateSkillWidth = (width: number) => {
  let amount = width + 10;
  if (amount > 100) {
    amount = 100;
  }
  return amount;
};

const SkillProgressBar: React.FC<SkillProgressBarProps> = ({
  skill,
  onClick,
}) => {
  const barRef = useRef<HTMLDivElement>(null);
  const numberRef = useRef<HTMLSpanElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);
  const { value, color } = skill;

  useEffect(() => {
    const handleScroll = () => {
      if (barRef.current && numberRef.current && labelRef.current) {
        const boxTop = barRef.current.getBoundingClientRect().top;
        const triggerBottom = (window.innerHeight / 5) * 4;
        const width = calculateSkillWidth(value);

        if (boxTop < triggerBottom) {
          barRef.current.classList.add("open");
          labelRef.current.classList.add("opened");
          const stringWidth = width - 18;
          numberRef.current.style.color = `${color}`;
          barRef.current.style.width = `${width}%`;
          barRef.current.style.backgroundColor = `${color}`;
        } else {
          barRef.current.classList.remove("open");
        }
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleScroll);
    };
  }, [value, color]);

  return (
    <div
      className="progress_inner skillsInner___"
      data-value={value}
      data-color={color}
      onClick={(event) => onClick(skill, event)}
    >
      <span>
        <span className="icon">{parse(skill.icon)}</span>
        <span className="label opened" ref={labelRef}>
          {
            SkillNames[
              skill.name.replace(/\s+/g, "") as keyof typeof SkillNames
            ]
          }
        </span>
        <span className="number" ref={numberRef}>
          <b>
            <Counter end={getYearsBasedOnValue(skill.value)}>Years</Counter>
          </b>
        </span>
      </span>
      <div className="background">
        <div className="bar open">
          <div className="bar_in open" ref={barRef}></div>
        </div>
      </div>
    </div>
  );
};

export default SkillProgressBar;
