// store.ts (updated)
import { create } from "zustand";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../utilities/Firebase/firebaseConfig";
import {
  ISkill,
  IWorkExperience,
  IInformation,
  IProjects,
} from "../types/firebase.store.interface";
import { SiteSetting } from "../types/site.interface";
import { Education } from "src/types/info.interface";

interface InformationState {
  data: IInformation | null;
  loading: boolean;
  error: string | null;
  fetchInformation: () => Promise<void>;
}

interface SkillsState {
  data: ISkill[] | null;
  loading: boolean;
  error: string | null;
  fetchSkills: () => Promise<void>;
  fetchSpecificSkill?: (id: string) => Promise<void>;
  fetchSkillsByIds?: (ids: string[]) => Promise<void>;
}

interface SiteSettingsState {
  data: SiteSetting;
  loading: boolean;
  error: string | null;
  fetchSiteInfo: () => Promise<void>;
}

interface WorkExperienceState {
  data: IWorkExperience[];
  loading: boolean;
  error: string | null;
  fetchWorkExperience: () => Promise<void>;
}
interface EducationState {
  data: Education[];
  loading: boolean;
  error: string | null;
  fetchEducation: () => Promise<void>;
}
interface ProjectsState {
  data: IProjects[];
  loading: boolean;
  error: string | null;
  fetchProjects: () => Promise<void>;
  fetchProjectById: (id: string) => Promise<void>;
}

const useSiteSettingsStore = create<SiteSettingsState>((set) => ({
  data: {
    brandName: "JPDesign.Tech",
    logo: {
      dark: "img/logo/brand-image.png",
      light: "img/logo/brand-image.png",
      mobile: "img/logo/logo192.png",
    },
  },
  loading: false,
  error: null,
  fetchSiteInfo: async () => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const querySnapshot = await getDocs(collection(fs, "siteInfo"));
      const dataList = querySnapshot.docs.map(
        (doc) => doc.data() as SiteSetting
      );
      set({ data: dataList[0], loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
}));

const useInformationStore = create<InformationState>((set) => ({
  data: null,
  loading: false,
  error: null,
  fetchInformation: async () => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const querySnapshot = await getDocs(collection(fs, "information"));
      const dataList = querySnapshot.docs.map(
        (doc) => doc.data() as IInformation
      );
      set({ data: dataList[0], loading: false }); // Assuming you only need the first document
    } catch (error) {
      set({
        error: (error as Error).message || "Failed to fetch information",
        loading: false,
      });
    }
  },
}));

const useWorkExpStore = create<WorkExperienceState>((set) => ({
  data: [],
  loading: false,
  error: null,
  fetchWorkExperience: async () => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const querySnapshot = await getDocs(
        collection(fs, "information/experiences/workingExperience")
      );
      const dataList = querySnapshot.docs.map(
        (doc) => doc.data() as IWorkExperience
      );
      console.log("work experience data:", dataList);
      set({ data: dataList, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
}));

const useEduExpStore = create<EducationState>((set) => ({
  data: [],
  loading: false,
  error: null,
  fetchEducation: async () => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const querySnapshot = await getDocs(
        collection(fs, "information/experiences/educationExperience")
      );
      const dataList = querySnapshot.docs.map((doc) => doc.data() as Education);
      console.log("education data:", dataList);
      set({ data: dataList, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
}));

const useSkillsProgressStore = create<SkillsState>((set) => ({
  data: null,
  loading: false,
  error: null,
  fetchSkills: async () => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const querySnapshot = await getDocs(
        collection(fs, "information/skills/skills")
      );
      const dataList = querySnapshot.docs.map((doc) => doc.data() as ISkill);
      set({ data: dataList, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
}));

const useSkillsStore = create<SkillsState>((set, get) => ({
  data: null,
  loading: false,
  error: null,
  fetchSkills: async () => {
    const { data } = get();
    if (data && data.length > 0) {
      return; // Data already fetched, no need to fetch again
    }
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const querySnapshot = await getDocs(
        collection(fs, "information/skills/skills")
      );
      const dataList = querySnapshot.docs.map((doc) => doc.data() as ISkill);
      console.log("fetching skills fetchSkills()", dataList);
      set({ data: dataList, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
  fetchSpecificSkill: async (id: string) => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const docRef = doc(fs, `information/skills/skills/${id}`);
      const docSnap = await getDoc(docRef);
      console.log(
        "fetching specific skill fetchSpecificSkill()",
        docSnap.data()
      );
      if (docSnap.exists()) {
        set({ data: [docSnap.data() as ISkill], loading: false });
      } else {
        set({ data: null, error: "No such document!", loading: false });
      }
    } catch (error) {
      set({ data: null, error: (error as Error).message, loading: false });
    }
  },
  fetchSkillsByIds: async (ids: string[]) => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const skillsQuery = query(
        collection(fs, "information/skills/skills"),
        where("__name__", "in", ids)
      );
      const querySnapshot = await getDocs(skillsQuery);
      const skills = querySnapshot.docs.map((doc) => doc.data() as ISkill);
      console.log("querying skill by ids fetchSkillsByIds()", skills);
      set({ data: skills, loading: false });
    } catch (error) {
      console.error("Error fetching skills:", error);
      set({ error: (error as Error).message, loading: false });
    }
  },
}));

const useProjectsStore = create<ProjectsState>((set) => ({
  data: [],
  loading: false,
  error: null,
  fetchProjects: async () => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const querySnapshot = await getDocs(collection(fs, "projects"));
      const dataList = querySnapshot.docs.map((doc) => doc.data() as IProjects);
      set({ data: dataList, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
  fetchProjectById: async (id: string) => {
    set({ loading: true, error: null });
    try {
      const fs = await db;
      const docRef = doc(fs, `projects/${id}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        set({ data: [docSnap.data() as IProjects], loading: false });
      } else {
        set({ data: [], error: "No such document!", loading: false });
      }
    } catch (error) {
      set({ data: [], error: (error as Error).message, loading: false });
    }
  },
}));

export {
  useSiteSettingsStore,
  useInformationStore,
  useSkillsStore,
  useWorkExpStore,
  useEduExpStore,
  useSkillsProgressStore,
  useProjectsStore,
};
