import React from "react";
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://34e2a71ea9f587f487853caeb6f04636@o4508084196605952.ingest.us.sentry.io/4508084198637568",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
