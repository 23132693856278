import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import LinkedSkillInfo from "./SkillsChain";
import ModalContent from "./ModalContent";
import {
  IWorkExperience,
  IEducationExperience,
} from "src/types/firebase.store.interface";

interface ExperienceModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  experience: IWorkExperience | IEducationExperience | null;
}

function isWorkExperience(experience: any): experience is IWorkExperience {
  return (experience as IWorkExperience).company !== undefined;
}

function isEducationExperience(
  experience: any
): experience is IEducationExperience {
  return (experience as IEducationExperience).university !== undefined;
}
Modal.setAppElement("#root"); // Set the app element for accessibility

const ExperienceModal: React.FC<ExperienceModalProps> = ({
  isOpen,
  onRequestClose,
  experience,
}) => {
  const [activeTab, setActiveTab] = useState<
    "role" | "responsibilities" | "about"
  >("role");
  // Disable background scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable background scroll
    } else {
      document.body.style.overflow = ""; // Re-enable background scroll
    }

    // Cleanup to ensure scroll is enabled when modal is closed
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);
  if (!experience) {
    return null;
  }

  const isWork = isWorkExperience(experience);
  const isEducation = isEducationExperience(experience);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Experience Details"
      className="modal experience-modal"
      overlayClassName="overlay"
    >
      <div className="modal-container">
        <div className="modal-header">
          <img
            src={experience.icon}
            alt={isWork ? experience.company : experience.university}
          />
          <div className="modal-subtitle">
            <h1>
              <strong>
                {isWork ? experience.company : experience.university}
              </strong>
            </h1>
            <h2>{isWork ? experience.position : experience.graduation}</h2>
            <h3>
              {experience.startDate} -{" "}
              {experience.endDate ? experience.endDate : "Present"}
            </h3>
          </div>
        </div>
        <div className="modal-tabs">
          <button
            onClick={() => setActiveTab("role")}
            className={activeTab === "role" ? "active" : ""}
          >
            {isWork ? "Role" : "Coursework"}
          </button>
          <button
            onClick={() => setActiveTab("responsibilities")}
            className={activeTab === "responsibilities" ? "active" : ""}
          >
            {isWork ? "Responsibilities" : "Achievements"}
          </button>
          <button
            onClick={() => setActiveTab("about")}
            className={activeTab === "about" ? "active" : ""}
          >
            About
          </button>
        </div>
        <ModalContent activeTab={activeTab} data={experience} />
        <div className="skills-used-wrapper">
          <LinkedSkillInfo
            usedSkills={experience.skillsUsed}
            appearance="modal"
          />
        </div>
        <button onClick={onRequestClose} className="close-modal">
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ExperienceModal;
