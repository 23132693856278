import React, { Key, useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import { IProjects } from "src/types/firebase.store.interface";
import PortfolioFrame from "./PortfolioFrame";
import { useProjectsStore } from "src/store/firebase.store";
import { ProjectTypes } from "src/types/info.interface";
import ProjectsModal from "./ProjectsModal";

const PortfolioGallery: React.FC = () => {
  const isotope = useRef<any>();
  const [filterKey, setFilterKey] = useState("*");
  const [selectedProject, setSelectedProject] = useState<IProjects | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: projects, loading, error, fetchProjects } = useProjectsStore();

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    setTimeout(() => {
      if (isotope.current) {
        isotope.current = new Isotope(".gallery_zoom", {
          itemSelector: ".grid-item",
          layoutMode: "masonry",
          percentPosition: true,
          masonry: {
            columnWidth: ".grid-item",
          },
        });
      }
    }, 500);
    return () => {
      if (isotope.current) isotope.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (isotope.current) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey]);

  const handleFilterKeyChange = (key: any) => () => {
    setFilterKey(key);
  };

  const openModal = (project: IProjects) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  const activeBtn = (value: any) => (value === filterKey ? "current" : "");

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="portfolio_gallery_container">
      <div className="portfolio_filter">
        <ul>
          <li>
            <a
              className={`c-pointer ${activeBtn("*")}`}
              onClick={handleFilterKeyChange("*")}
            >
              All
            </a>
          </li>
          <li>
            <a
              className={`c-pointer ${activeBtn(ProjectTypes.COURSE)}`}
              onClick={handleFilterKeyChange(ProjectTypes.COURSE)}
            >
              Courses
            </a>
          </li>
          <li>
            <a
              className={`c-pointer ${activeBtn(ProjectTypes.FREELANCE)}`}
              onClick={handleFilterKeyChange(ProjectTypes.FREELANCE)}
            >
              Freelancing
            </a>
          </li>
          <li>
            <a
              className={`c-pointer ${activeBtn(ProjectTypes.HOBBY)}`}
              onClick={handleFilterKeyChange(ProjectTypes.HOBBY)}
            >
              Hobbies
            </a>
          </li>
          <li>
            <a
              className={`c-pointer ${activeBtn(ProjectTypes.WORK)}`}
              onClick={handleFilterKeyChange(ProjectTypes.WORK)}
            >
              Work
            </a>
          </li>
        </ul>
      </div>
      <div className="jpdesign_portfolio_titles" />
      <div className="portfolio_list wow fadeInUp" data-wow-duration="1s">
        <ul className="gallery_zoom grid">
          {projects.map((project: IProjects, i: Key | null | undefined) => (
            <PortfolioFrame
              onClick={() => openModal(project)}
              key={i}
              project={project}
            />
          ))}
        </ul>
      </div>
      {isModalOpen && (
        <ProjectsModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          project={selectedProject}
        />
      )}
    </div>
  );
};

export default PortfolioGallery;
