export interface Info {
  name?: string
  address?: string
  bio?: string
  mainSkill?: string
  img?: string
  skills?: Skill[]
  social?: Social[]
  contact?: Contact
}

export interface Skill {
  name: string;
  icon: string;
  color: string;
  value: number;
  description: string;
  hueCode?: string;
}

export enum SkillNames {
  amplitude = "Amplitude",
  angular = "Angular",
  docker = "Docker",
  ffmpeg = "FFmpeg",
  firebase = "Firebase",
  gcp = "Google Cloud Platform",
  grafana = "Grafana",
  graphql = "GraphQL",
  honeycomb = "Honeycomb",
  java = "Java",
  javascript = "JavaScript",
  mediaapi = "MediaStream API",
  nextjs = "Next.js",
  nodejs = "Node.js",
  postgres = "Postgres",
  python = "Python",
  react = "React",
  redux = "Redux",
  rust = "Rust",
  rxjs = "RxJS",
  sentry = "Sentry",
  temporalio = "Temporal.io",
  typescript = "TypeScript",
  vercel = "Vercel",
  vim = "Vim",
  vscode = "VSCode",
  webrtc = "WebRTC",
  cordova = "Cordova",
  "react-native" = "React Native",
  firestore = "Firestore",
  github = "GitHub",
  deeplearning = "Deep Learning",
  cnn = "Convolutional Neural Networks",
  rnn = "Recurrent Neural Networks",
  lstm = "LSTM",
  pyqt = "Qt",
  flutter = "Flutter",
  dart = "Dart",
  spark = "Apache Spark",
  hadoop = "Hadoop",
  scala = "Scala",
  mips = "MIPS",
  assembly = "Assembly",
  opencv = "OpenCV",
  "raspberry-pi" = "Raspberry Pi",
  "c++" = "C++",
  "c" = "C",
  django = "Django",
  matlab = "MATLAB",
  tensorflow = "TensorFlow",
  go = "Go",
  jira = "Jira",
  rollbar = "Rollbar",
  arduino = "Arduino",
}

export interface Social {
  name: string
  icon: string
  url: string
}

export interface Contact {
  address: string
  email: string
  phn: string
}

export interface WorkExperience {
  id: number;
  company: string;
  position: string;
  icon: string;
  skillsUsed: string[];
  companyAbout: string;
  summary: string;
  startDate: string;
  endDate: string;
  companyUrl: string;
  details?: string[];
}

export interface Education {
  startDate: string;
  endDate: string;
  university: string;
  graduation: string;
  universityUrl: string;
  details: string[];
  id: number;
  about: string;
  icon: string;
  description: string;
  skillsUsed: SkillNames[];
}


export interface Projects {
  id: number;
  name: string;
  type: string;
  mainImage: string;
  startDate: string;
  endDate: string;
  description: string;
  details: string[];
  about: string;
  techStack: SkillNames[];
  urls: ProjectURLs;
  projectType: ProjectTypes;

  //DEBUGGING
  imageUrl?: string;
  largeImageUrl?: string;
  title?: string;
}

interface ProjectURLs {
  projectUrl?: string[];
  githubUrl?: string;
  mediaUrl?: string[];
}

export enum ProjectTypes {
  HOBBY = "Hobby",
  FREELANCE = "Freelance",
  COURSE = "Course",
  CERTIFICATION = "Certification",
  WORK = "Work",
}
