import Timeline from "../components/Timeline";

const Background = () => {
  return (
    <div className="jpdesign_section" id="background">
      <div className="jpdesign_testimonials">
        <div className="container">
          <div className="jpdesign_main_title" data-align="center">
            <span>My Background</span>
            <h3>Work & Education Experience</h3>
            <p>
              Throughout the year's I've built a long career in Software
              Engineering.
            </p>
            <div className="brush_1 wow fadeInRight" data-wow-duration="5s">
              <img src="img/svg/thesis.svg" alt="image" />
            </div>
            <div className="brush_2 wow zoomIn" data-wow-duration="2s">
              <img src="img/svg/cloud-library.svg" alt="image" />
            </div>
          </div>
          <div className="timeline">
            <Timeline />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Background;
