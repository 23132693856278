import { initializeApp, FirebaseOptions } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBeg1TpO2CwHyInk9o89QDjpQMg2n5OXQs",
  authDomain: "personalportfolio-4caf3.firebaseapp.com",
  databaseURL: "https://personalportfolio-4caf3.firebaseio.com",
  projectId: "personalportfolio-4caf3",
  storageBucket: "personalportfolio-4caf3.appspot.com",
  messagingSenderId: "500588289010",
  appId: "1:500588289010:web:2e10dff1307db9504a296b",
  measurementId: "G-L7ZLQ1MDD4",
};

async function initializeFirebase() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firestore
  const db = getFirestore(app);

  return db;
}

export const db = initializeFirebase();
